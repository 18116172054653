<template>

  <div>

    <!-- 項目 -->
    <b-card
      v-for="(data, index) in fetchData"
      :key="index"
      no-body
      class="mx-2 mx-sm-5"
      :class="index == 0 ? 'mt-5' : 'mt-3'"
    >
      <!-- user suggestion  -->
      <div class="d-flex justify-content-start align-items-start p-75">
        <!-- <b-avatar :src="data.image" class="mr-50" size="60" /> -->
        <b-img
          :src="data.image"
          rounded
          fluid
          class="mr-50 width-100 height-100"
          style="object-fit: cover;"
        />
        <div class="user-page-info">
          <h4 class="font-weight-bolder mb-0">
            {{ data.name }}
          </h4>
          <small class="text-muted">{{ data.name }}</small>
        </div>

        <div
          style="position: absolute; right: 10px; bottom: 5px;"
          class="btn-icon ml-auto"
        >
          <!-- 180 min/$1000起 -->
          <small class="text-muted">{{ data.interval }} min</small><span
            v-if="data.price > 0"
            class="h5 font-weight-bolder text-danger"
          > / ${{ data.price }}起</span>
        </div>

        <div
          style="position: absolute; right: -10px; top: -10px;"
          class="btn-icon ml-auto"
          @click="$router.push({ name: 'reserve-edit-page', params: { slug: $route.params.slug, id: data.id } })"
        >
          <b-button
            variant="danger"
            class="btn-icon ml-auto p-0"
            style="width:40px; height:40px; "
          >
            預約
          </b-button>
        </div>
      </div>
      <!--/ user suggestion  -->
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BImg, BButton,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

//
import dataStoreModule from '../reserveStoreModule'

export default {
  components: {
    BCard,
    BImg,
    BButton,
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-client-reserve'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, dataStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    // Use toast
    const toast = useToast()

    // Use Func
    const fetchData = ref([])
    const fetchAll = () => {
      store.dispatch(`${APP_STORE_MODULE_NAME}/fetchReserves`, { date: new Date() })
        .then(response => {
          fetchData.value = response.data
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching users list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    fetchAll()

    return {
      fetchData,
    }
  },
}
</script>
