import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchReserves(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/frontend/reserves', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchReserve(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/frontend/reserves/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // // 訂單
    // addReserveOrder(ctx, itemData) {
    //     return new Promise((resolve, reject) => {
    //         axios
    //             .post(`/api/frontend/app/${router.currentRoute.params.botId}/reserve_orders`, itemData)
    //             .then(response => resolve(response))
    //             .catch(error => reject(error))
    //     })
    // },
  },
}
